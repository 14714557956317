export default class Erp {
  

  static appLogo = () => {
    switch(DOMAIN) {
      case 'RBRO_DOMAIN':
        return '/custom/rbro-logo.jpg'
      case 'AUTOLIV_DOMAIN':
        return '/custom/autoliv-logo.png'
      case 'DIGIT_DOMAIN':
      case 'EINFRA_DOMAIN':
      case 'ACCESS_DOMAIN':
        return '/images/logo.png'
      default:
        return THEME === 'dark' ? '/images/logo-dark.png' : '/images/logo.png'
    }
  }

  static processing = () => {
    return ['APPSSM_DOMAIN', 'STAGING_DOMAIN', 'DEV_DOMAIN'].includes(DOMAIN)
  }

  static rememberMe = () => {
    return !['RBRO_DOMAIN', 'AUTOLIV_DOMAIN'].includes(DOMAIN)
  }

  static moduleMeetings = () => {
    return !['RBRO_DOMAIN'].includes(DOMAIN)
  }

  static disableGuest = () => {
    return ['RBRO_DOMAIN'].includes(DOMAIN)
  }

  static enableSU = () => {
    return !['RBRO_DOMAIN'].includes(DOMAIN)
  }

  static enableImportV2 = () => {
    return !['RBRO_DOMAIN', 'AUTOLIV_DOMAIN', 'EINFRA_DOMAIN'].includes(DOMAIN)
  }

  
  static disableBackupContacts = () => {
    return ['RBRO_DOMAIN'].includes(DOMAIN)
  }

  
  static disableBackupDrive = () => {
    return ['RBRO_DOMAIN'].includes(DOMAIN)
  }

  // we disable all inline Op List edit due to performance issues
  static disableInlineEditOp = () => {
    return true
  }

  static disableCI = () => {
    return ['RBRO_DOMAIN'].includes(DOMAIN)
  }
  
  static personalSupport = () => {
    return ['RBRO_DOMAIN', 'AUTOLIV_DOMAIN', 'EINFRA_DOMAIN', 'ACCESS_DOMAIN', 'ADREM_DOMAIN'].includes(DOMAIN)
  }
  
  static personalSurvey = () => {
    return ['AUTOLIV_DOMAIN'].includes(DOMAIN)
  }
  
  
  static disposableGDPR = () => {
    return ['RBRO_DOMAIN'].includes(DOMAIN)
  }

  
  static limitToSign = () => {
    switch(DOMAIN) {
      case 'RBRO_DOMAIN':
        return [15, 30]
      default:
        return [10, 25, 50]
    }
  }

  static authExternalId = () => {
    return ['AUTOLIV_DOMAIN'].includes(DOMAIN)
  }

  static showGuide = () => {
    return !['DIGIT_DOMAIN'].includes(DOMAIN)
  }

  static enableApps = () => {
    return ['DEV_DOMAIN', 'STAGING_DOMAIN', 'APPSSM_DOMAIN', 'AUTOLIV_DOMAIN', 'DIGIT_DOMAIN', 'EINFRA_DOMAIN'].includes(DOMAIN)
  }

  static enableRFID = () => {
    return Erp.enableApps()
  }

  static ssoName = (prefix, name) => {
    switch (DOMAIN) {
      case 'ACCESS_DOMAIN':
        return name
      default:
        return prefix + ' ' + name
    }
  }

  static disableBrand = () => {
    return ['DIGIT_DOMAIN'].includes(DOMAIN)
  }

}
